<template>
  <b-container v-if="createAny('users')" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>{{ $t("labels.check_hours") }}</h1>
    </div>
    <!-- Filter bar -->
    <b-row class="filter-bar">
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.from')">
          <b-form-input type="week" v-model="startw" />
        </b-form-group>
      </b-col>
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.to')">
          <b-form-input type="week" v-model="endw" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-checkbox v-model="show_all" name="checkbox-1" value="1" unchecked-value="0">{{
        $t('labels.showclosedhours') }}</b-form-checkbox>
    
        <b-form-checkbox v-model="show_absent" name="checkbox-1" value="1" unchecked-value="0">{{
        $t('labels.showabsentweeks') }}</b-form-checkbox>        
      </b-col>      
    </b-row>
    <b-table sort-icon-left :items="thisweekscontracts()" :fields="fields" :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :current-page="currentPage" :per-page="perPage">
      <template #cell(hours)="row">

        <table>
          <td v-for="(item, index) in row.item.dates" :key="index">{{dateToNL(item)}}
            <div v-if="searchHours(item, row.item.hours)">
              {{ searchHours(item, row.item.hours).hours }}
              <font-awesome-icon v-if="searchHours(item, row.item.hours).agree_company==null" color="red"
                icon="signature" />
              <font-awesome-icon v-else color="green" icon="signature" />
            </div>
            <div v-else>
              <font-awesome-icon color="red" icon="times" />
            </div>
          </td>
        </table>
      </template>
      <template #cell(actions)="row">
        <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">

            <b-dropdown-item v-if="row.item.isabsent" @click="setPresent(row.item)">
              <font-awesome-icon icon="eye" /> {{ $t('labels.setpresent') }}
            </b-dropdown-item>
            <b-dropdown-item v-else @click="setAbsent(row.item)">
              <font-awesome-icon icon="eye-slash" /> {{ $t('labels.setabsent') }}
            </b-dropdown-item>            
            <b-dropdown-item @click="showUserHours(row.item.contract_id, parseInt(row.item.week.substring(1, 4)))"
             :title="$t('labels.showhours')">
              <font-awesome-icon icon="clipboard-list" /> {{ $t('labels.showhours') }}
            </b-dropdown-item>
          </b-dropdown>

      </template>
      <template #cell(lastname)="row" @click.native.capture="show_user()">
        <p v-html="userinfo(row)" />
      </template>
      <template #cell(company_id)="row">
        <p v-html="companyinfo(row)" />
      </template>
      <template #cell(sign_id)="row">
        <p v-html="signinfo(row)" />
      </template>
      <template #cell(smszzpr)="row">
        <a href="javascript:;" class="action-item" @click="sendSMSUser(row.item)" :title="$t('labels.sendsms')">
          <font-awesome-icon size="2x" icon="sms" />
        </a>
      </template>
      <template #cell(smssign)="row">
        <a href="javascript:;" class="action-item" @click="sendSMSSigner(row.item)" :title="$t('labels.sendsms')">
          <font-awesome-icon size="2x" icon="sms" />
        </a>
      </template>
      <template #cell(totalHours)="row">
        {{ row.item.week }}
        <p v-html="gethoursinfo(row)" @click="showUserHours(row.item.contract_id, parseInt(row.item.week.substring(1, 4)))" />
      </template>
      <template #cell(signed)="row">
        <font-awesome-icon v-if="!row.item.closed" color="red" icon="times" :title="$t('labels.weeknotclosed')" />
        <div v-else>
          <font-awesome-icon v-if="row.item.signed" color="green" icon="signature" :title="$t('labels.hoursstatuschecked')" />
          <font-awesome-icon v-else color="red" icon="signature" :title="$t('labels.hourswaiting')"  />
        </div>

      </template>
    </b-table>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
    <BSAbsentModal :contract_id="selected_contractID" :weeknumber="selected_week" :yearnumber="selected_year" />

  </b-container>
</template>
<script>
import axios from 'axios'
import { mapActions, mapState, mapGetters } from 'vuex'
import send_sms_modal from './modals/SendSMSModal'
import BSAbsentModal from './modals/BSAbsentModal.vue'

export default {
  name: 'HoursOverview',
  data() {
    return {
      startw: null,
      endw: null,
      hashours: 'yes',
      show_all: "0",
      show_absent: "0",
      allhours: [],
      sortBy: 'lastname',
      sortDesc: false,
      sortDirection: 'desc', 
      currentPage: 1,
      perPage: 20,   
      selected_contractID:0,
      selected_week:0,
      selected_year:0,
      totalRowsNow:500,
      totalRows:0
    }
  },
  mounted() {
    let now = new Date(new Date(new Date().getTime() - + 7 * 24 * 60 * 60 * 1000))
    let sweek = this.getWeekNumber(now)
    // now.setDate(now.getDate() - 7);
    sweek = this.getWeekNumber(now)
    // console.log("S", now.getDay(), sweek)
    // if(now.getDay()==1){
    //   sweek[1]++
    //   this.startw = parseInt(sweek[1] - 1) < 10 ? sweek[0] + "-W0" + (sweek[1]) : sweek[0] + "-W" + (sweek[1] - 1)
    //   this.endw = parseInt(sweek[1]) < 10 ? sweek[0] + "-W0" + sweek[1] : sweek[0] + "-W" + sweek[1]
    // } else{
      this.startw = parseInt(sweek[1] - 1) < 10 ? sweek[0] + "-W0" + (sweek[1]) : sweek[0] + "-W" + (sweek[1] - 1)
      this.endw = parseInt(sweek[1]) < 10 ? sweek[0] + "-W0" + sweek[1] : sweek[0] + "-W" + sweek[1]

    // }
    // console.log(this.startw, this.endw)
  },
  computed: {
    ...mapState([
      'contracts',
      'branches',
      'avatars',
      'planning',
      'workfields',
      'tags',
      'current_user',
      'showBlacklist',
      'hours',
      'urenbonnen',
      'invoiceextra'
    ]),
    ...mapGetters([
      'branche_by_id',
      'project_by_id',
      'company_by_id',
      'user_by_id',
      'user_photo_by_id',
      'activebranches',
      'username_by_id',
      'confirm_modal',
      'owners',
      'find_last_planning_user',
      'contract_by_id',
      'server_url',
      'createAny'
    ]),
    // totalRows() {
    //   return this.thisweekscontracts.length
    // },
    // thisweekscontracts() {
    //   let values = [];
    //   if (this.startw) {
    //     let startyear = this.startw.substring(0, 4)
    //     let startweek = parseInt(this.startw.substring(6))
    //     var d = new Date(startyear, 0, 1);
    //     if(d.getDay()==1){
    //       d.setDate(d.getDate() + ((startweek-1) * 7));
    //     } else{
    //       d.setDate(d.getDate() + (startweek * 7));
    //     }
    //     var startdate = this.getMonday(d)
    //     let endyear = this.endw.substring(0, 4)
    //     let endweek = parseInt(this.endw.substring(6))
    //     d = new Date(endyear, 0, 1);
    //     d.setDate(d.getDate() + (endweek * 7));
    //     // var enddate = this.getMonday(d.getTime() + 6 * 24 * 60 * 60 * 1000)
    //     var enddate = new Date(d.getTime())
    //     // console.log(startdate, enddate)

    //     var loopstart = startdate
    //     for (var i = 0; i < this.contracts.length; i++) {
    //       if (this.user_by_id(this.contracts[i].user_id) != null) {
    //         let userplan = this.userplanning(this.contracts[i].user_id).filter(p =>p.project_id == this.contracts[i].project_id)
    //         let chours = this.has_hours(this.contracts[i].id)
    //         for (var p = 0; p < userplan.length; p++) {
    //           loopstart = startdate
    //           // console.log(loopstart, enddate)
    //           while (loopstart < enddate) {
    //             var loopend = new Date(loopstart.getTime() + 6 * 24 * 60 * 60 * 1000);
    //             let row = {};
    //             let betweendates = false
    //             let sweek = this.getWeekNumber(loopstart)
    //             let project = this.project_by_id(this.contracts[i].project_id)
    //             if (project) {

    //               row.realweek = parseInt(sweek[1])
    //               row.week = row.realweek < 10 ? "W0" + (sweek[1]) : "W" + (sweek[1])
    //               row.year = parseInt(sweek[0])
    //               row.startdate = loopstart
    //               row.enddate = loopend
    //               row.project = userplan[p].project_name
    //               row.user_id = userplan[p].user_id
    //               row.lastname = this.user_by_id(userplan[p].user_id).lastname
    //               row.sign_id = userplan[p].sign_id
    //               row.signed_zzpr = this.contracts[i].signed_zzpr
    //               row.signed_company = this.contracts[i].signed_company
    //               let projstartdate = new Date(userplan[p].startdate)
    //               let projenddate = new Date(userplan[p].enddate)
    //               projstartdate.setHours(0, 0, 0, 0);
    //               projenddate.setHours(0, 0, 0, 0);
    //               loopend.setHours(0, 0, 0, 0);
    //               loopstart.setHours(0, 0, 0, 0);
    //               if ((projstartdate <= loopend && projenddate >= loopstart)) {
    //                 betweendates = true
    //               }
    //               // if(this.contracts[i].user_id==1241){
    //               //   console.log(sweek)
    //               //   console.log(projstartdate)
    //               //   console.log(loopend)
    //               //   console.log(projenddate)
    //               //   console.log(loopstart)
    //               //   console.log(betweendates)

    //               // }
                  
    //               let company = this.company_by_id(project.company_id)
    //               row.company_id = company.id
    //               row.contract_id = this.contracts[i].id
    //               row.hours = []
    //               const wd = this.getWeekDates(row.realweek, row.year)
    //               chours.forEach(element => {
    //                 // if(this.contracts[i].user_id==1241){
    //                 // console.log(element)
    //                 // }
    //                 if(wd.includes(element.workdate)) {
    //                   row.hours.push(element)
    //                 }
    //               })
    //               // console.log(row.realweek, row.year, wd)
    //               row.closed =  row.hours.filter(ahour => ahour.close_hours != null && wd.filter(adate => adate == ahour.workdate).length>0).length > 0
                  
    //               row.signed = row.hours.filter(ahour => ahour.agree_company == 1 && wd.filter(adate => adate== ahour.workdate).length>0).length > 0
    //             }
    //             let absent = this.invoiceextra.filter(i => i.contract_id == row.contract_id && i.week == sweek[1] && i.year == row.year)
    //             let isabsent = (absent.length>0 && absent[0].absent==1)
    //             row.isabsent = isabsent
    //             if(this.show_absent=="1"){
    //               isabsent=false
    //             }
    //             if (betweendates && !isabsent) {
    //               if (this.show_all == '1' || !row.signed) {
    //                 values.push(row)
    //               }
    //             }
    //             loopstart = new Date(loopstart.getTime() + 7 * 26 * 60 * 60 * 1000);
    //           }
    //         }
    //       }
    //     }
    //   }
    //   this.totalRows = values.length
    //   return values
    // },
    static_weekdays() {
      return this.allhours
    },
    fields() {
      if(this.smsEnabled()){
        return [
          { key: 'week', sortable: true, sortDirection: 'desc' },
          // {key:'startdate'},
          // {key:'enddate'},
          { key: 'lastname', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
          { key: 'smszzpr', label: '' },
          { key: 'company_id', label: this.$i18n.t('labels.client'), sortable: true, sortDirection: 'desc' },
          { key: 'sign_id', label: this.$i18n.t('labels.authorizedsignatory'), sortable: true, sortDirection: 'desc' },
          { key: 'smssign', label: '' },
          { key: 'totalHours' },
          { key: 'signed' , sortable: true, sortDirection: 'desc'},
          { key: 'actions', label: this.$i18n.t('labels.actions') },
        ]
      } else {
        return [
          { key: 'week', sortable: true, sortDirection: 'desc' },
          { key: 'lastname', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
          { key: 'company_id', label: this.$i18n.t('labels.client'), sortable: true, sortDirection: 'desc' },
          { key: 'sign_id', label: this.$i18n.t('labels.authorizedsignatory'), sortable: true, sortDirection: 'desc' },
          { key: 'totalHours' },
          { key: 'signed' },
          { key: 'actions', label: this.$i18n.t('labels.actions') },
        ]        
      }
    },
  },
  methods: {
    thisweekscontracts() {
      let values = [];
      if (this.startw) {
        let startyear = this.startw.substring(0, 4)
        let startweek = parseInt(this.startw.substring(6))
        // var d = new Date(startyear, 0, 1);
        // if(d.getDay()==1){
        //   d.setDate(d.getDate() + ((startweek-1) * 7));
        // } else{
        //   d.setDate(d.getDate() + (startweek * 7));
        // }
        // var startdate = this.getMonday(d)
        let endyear = this.endw.substring(0, 4)
        let endweek = parseInt(this.endw.substring(6))
        // d = new Date(endyear, 0, 1);
        // d.setDate(d.getDate() + (endweek * 7));
        // // var enddate = this.getMonday(d.getTime() + 6 * 24 * 60 * 60 * 1000)
        // var enddate = new Date(d.getTime())
        // console.log(startdate, enddate)
        

        var startdate = new Date(this.getWeekOfYear(parseInt(startyear),startweek)[0])
        var enddate =  new Date(this.getWeekOfYear(parseInt(endyear),endweek)[6])
        // console.log(">> new",startdate, enddate)

        // let years = ["2020","2021","2022","2023","2024","2025","2026","2027"]
        // years.forEach(startyear=>{
        //   console.log(">>>>>",startyear,43, this.getWeekOfYear(parseInt(startyear),43))
        //   console.log(">>>>>",startyear,44, this.getWeekOfYear(parseInt(startyear),44))
        //   console.log(">>>>>",startyear,45, this.getWeekOfYear(parseInt(startyear),45))
        // })
        // find userplanning in date
        let ups =   this.planning.filter(up =>{
          let projstartdate = new Date(up.startdate)
          let projenddate = new Date(up.enddate)
          projstartdate.setHours(0, 0, 0, 0);
          projenddate.setHours(0, 0, 0, 0);
          startdate.setHours(0, 0, 0, 0);
          enddate.setHours(0, 0, 0, 0);
          if ((projstartdate <= enddate && projenddate >= startdate)) {
            return true
          }
        })
        let planning_ids = [...new Set(ups.map(item => item.id))]
        var loopstart = startdate
        this.contracts.filter(c =>planning_ids.indexOf(c.planning_id)!=-1).forEach(c =>{
          if (this.user_by_id(c.user_id) != null) {
            let userplan = this.userplanning(c.user_id).filter(p =>p.project_id == c.project_id)
            let chours = this.has_hours(c.id)
            for (var p = 0; p < userplan.length; p++) {
              loopstart = startdate
              // console.log(loopstart, enddate)
              while (loopstart <= enddate) {
                var loopend = new Date(loopstart.getTime() + 6 * 24 * 60 * 60 * 1000);
                let row = {};
                let betweendates = false
                let sweek = this.getWeekNumber(loopstart)
                let project = this.project_by_id(c.project_id)
                if (project) {

                  row.realweek = parseInt(sweek[1])
                  row.week = row.realweek < 10 ? "W0" + (sweek[1]) : "W" + (sweek[1])
                  row.year = parseInt(sweek[0])
                  row.startdate = loopstart
                  row.enddate = loopend
                  row.project = userplan[p].project_name
                  row.user_id = userplan[p].user_id
                  row.lastname = this.user_by_id(userplan[p].user_id).lastname
                  row.sign_id = userplan[p].sign_id
                  row.signed_zzpr = c.signed_zzpr
                  row.signed_company = c.signed_company
                  let projstartdate = new Date(userplan[p].startdate)
                  let projenddate = new Date(userplan[p].enddate)
                  projstartdate.setHours(0, 0, 0, 0);
                  projenddate.setHours(0, 0, 0, 0);
                  loopend.setHours(0, 0, 0, 0);
                  loopstart.setHours(0, 0, 0, 0);
                  if ((projstartdate <= loopend && projenddate >= loopstart)) {
                    betweendates = true
                  }
                  // if(c.user_id==1241){
                  //   console.log(sweek)
                  //   console.log(projstartdate)
                  //   console.log(loopend)
                  //   console.log(projenddate)
                  //   console.log(loopstart)
                  //   console.log(betweendates)

                  // }
                  
                  let company = this.company_by_id(project.company_id)
                  row.company_id = company.id
                  row.contract_id = c.id
                  row.hours = []
                  const wd = this.getWeekDates(row.realweek, row.year)
                  // if(row.contract_id==1615 && row.realweek == 43){
                  //   console.log("WD", wd)
                  // }
                  chours.forEach(element => {
                    if(row.user_id==1620){
                    console.log(">>>",element)
                    }
                    if(wd.includes(element.workdate)) {
                      row.hours.push(element)
                    }
                  })
                  // console.log(row.realweek, row.year, wd)
                  row.closed =  row.hours.filter(ahour => ahour.close_hours != null && wd.filter(adate => adate == ahour.workdate).length>0).length > 0
                  
                  row.signed = row.hours.filter(ahour => ahour.agree_company == 1 && wd.filter(adate => adate== ahour.workdate).length>0).length > 0
                }
                let absent = this.invoiceextra.filter(i => i.contract_id == row.contract_id && i.week == sweek[1] && i.year == row.year)
                let isabsent = (absent.length>0 && absent[0].absent==1)
                row.isabsent = isabsent
                if(this.show_absent=="1"){
                  isabsent=false
                }
                if (betweendates && !isabsent) {
                  if (this.show_all == '1' || !row.signed) {
                    values.push(row)
                  }
                }
                if(row.contract_id==1615){
                  console.log(row)
                }
                loopstart = new Date(loopstart.getTime() + 7 * 26 * 60 * 60 * 1000);
              }
            }
          }

        })
        // for (var i = 0; i < this.contracts.filter(c =>planning_ids.indexOf(c.planning_id)!=-1).length; i++) {
        //   if (this.user_by_id(this.contracts[i].user_id) != null) {
        //     let userplan = this.userplanning(this.contracts[i].user_id).filter(p =>p.project_id == this.contracts[i].project_id)
        //     let chours = this.has_hours(this.contracts[i].id)
        //     for (var p = 0; p < userplan.length; p++) {
        //       loopstart = startdate
        //       // console.log(loopstart, enddate)
        //       while (loopstart < enddate) {
        //         var loopend = new Date(loopstart.getTime() + 6 * 24 * 60 * 60 * 1000);
        //         let row = {};
        //         let betweendates = false
        //         let sweek = this.getWeekNumber(loopstart)
        //         let project = this.project_by_id(this.contracts[i].project_id)
        //         if (project) {

        //           row.realweek = parseInt(sweek[1])
        //           row.week = row.realweek < 10 ? "W0" + (sweek[1]) : "W" + (sweek[1])
        //           row.year = parseInt(sweek[0])
        //           row.startdate = loopstart
        //           row.enddate = loopend
        //           row.project = userplan[p].project_name
        //           row.user_id = userplan[p].user_id
        //           row.lastname = this.user_by_id(userplan[p].user_id).lastname
        //           row.sign_id = userplan[p].sign_id
        //           row.signed_zzpr = this.contracts[i].signed_zzpr
        //           row.signed_company = this.contracts[i].signed_company
        //           let projstartdate = new Date(userplan[p].startdate)
        //           let projenddate = new Date(userplan[p].enddate)
        //           projstartdate.setHours(0, 0, 0, 0);
        //           projenddate.setHours(0, 0, 0, 0);
        //           loopend.setHours(0, 0, 0, 0);
        //           loopstart.setHours(0, 0, 0, 0);
        //           if ((projstartdate <= loopend && projenddate >= loopstart)) {
        //             betweendates = true
        //           }
        //           // if(this.contracts[i].user_id==1241){
        //           //   console.log(sweek)
        //           //   console.log(projstartdate)
        //           //   console.log(loopend)
        //           //   console.log(projenddate)
        //           //   console.log(loopstart)
        //           //   console.log(betweendates)

        //           // }
                  
        //           let company = this.company_by_id(project.company_id)
        //           row.company_id = company.id
        //           row.contract_id = this.contracts[i].id
        //           row.hours = []
        //           const wd = this.getWeekDates(row.realweek, row.year)
        //           chours.forEach(element => {
        //             // if(this.contracts[i].user_id==1241){
        //             // console.log(element)
        //             // }
        //             if(wd.includes(element.workdate)) {
        //               row.hours.push(element)
        //             }
        //           })
        //           // console.log(row.realweek, row.year, wd)
        //           row.closed =  row.hours.filter(ahour => ahour.close_hours != null && wd.filter(adate => adate == ahour.workdate).length>0).length > 0
                  
        //           row.signed = row.hours.filter(ahour => ahour.agree_company == 1 && wd.filter(adate => adate== ahour.workdate).length>0).length > 0
        //         }
        //         let absent = this.invoiceextra.filter(i => i.contract_id == row.contract_id && i.week == sweek[1] && i.year == row.year)
        //         let isabsent = (absent.length>0 && absent[0].absent==1)
        //         row.isabsent = isabsent
        //         if(this.show_absent=="1"){
        //           isabsent=false
        //         }
        //         if (betweendates && !isabsent) {
        //           if (this.show_all == '1' || !row.signed) {
        //             values.push(row)
        //           }
        //         }
        //         loopstart = new Date(loopstart.getTime() + 7 * 26 * 60 * 60 * 1000);
        //       }
        //     }
        //   }
        // }
      }
      this.totalRows = values.length
      return values
    },    
    userinfo(row) {
      return '<a href="/#/user/' + row.item.user_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.user_id) + '</a><br/>' +
        '<a href="tel:' + this.user_by_id(row.item.user_id).mobile + '" class="link-item">' + this.user_by_id(row.item.user_id).mobile + '</a>'
    },
    companyinfo(row) {

      return '<a href="/#/company/' + row.item.company_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.company_by_id(row.item.company_id).company_name + '</a><br/>'+
        row.item.project+'<br>'
    },
    signinfo(row) {
      return '<a href="/#/user/' + row.item.sign_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.sign_id) + '</a><br/>' +
        '<a href="tel:' + this.user_by_id(row.item.sign_id).mobile + '" class="link-item">' + this.user_by_id(row.item.sign_id).mobile + '</a>'
    },
    sendSMSUser(row) {
      let sms = "Dag " + this.user_by_id(row.user_id).firstname + ", mag ik je vragen om de uren van week " + row.week + " van project " + row.project + " te vullen? Alvast bedankt. M.v.gr. ZZPR.nl"
      this.$modal.hide('send_sms_modal')
      this.$modal.show(send_sms_modal, {
        message: sms,
        user_id: row.user_id
      }, {
        name: 'send_sms_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '600px'
      })
      this.$emit('close')
    },
    sendSMSSigner(row) {
      let sms = "Dag " + this.user_by_id(row.sign_id).firstname + ", mag ik je vragen om de uren van " + this.username_by_id(row.user_id) + " in week " + row.week + "  te controleren en daarna te accorderen? Alvast bedankt. M.v.gr. ZZPR.nl"
      this.$modal.hide('send_sms_modal')
      this.$modal.show(send_sms_modal, {
        message: sms,
        user_id: row.sign_id
      }, {
        name: 'send_sms_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '600px'
      })
      this.$emit('close')
    },
    ...mapActions([
      'delete_user',
      'save_user'
    ]),
    usersort(a, b) {
      if (a.user_id < b.user_id) {
        return -1;
      }
      if (a.user_id > b.user_id) {
        return 1;
      }
      return 0;
    },
    userplanning(user_id) {
      return this.planning.filter(aplanning => aplanning.user_id == user_id)
    },
    has_hours(contract_id) {
      // let res = []
      // this.hours.filter(ahour => ahour.contract_id == contract_id).forEach(element => {
      //   if (this.static_weekdays.includes(element.workdate)) {
      //     res.push(element)
      //   }
      // })
      // return 
      if(contract_id==1615){
        console.log("SWD",this.static_weekdays)
        console.log( "hours", this.hours.filter(ah =>ah.contract_id==contract_id))
      }
      return this.hours.filter(ahour => ahour.contract_id == contract_id && this.static_weekdays.includes(ahour.workdate)) 

    },
    doHours(hours) {
      if (hours) {
        return hours.length
      } else {
        return 0
      }
    },
    searchHours(nameKey, myArray) {
      return myArray.filter(element => element.workdate == nameKey)
      // for (var i = 0; i < myArray.length; i++) {
      //   if (myArray[i].workdate === nameKey) {
      //     return myArray[i];
      //   }
      // }
    },
    gethoursinfo(row) {
      // if(row.item.user_id==1039){
      //   console.log(row)
      // }
      var totalnormal = 0
      var declaraties = new Object()
      let contract = this.contract_by_id(row.item.contract_id)
      let userplanning = this.planning_by_id(this.contract_by_id(row.item.contract_id).planning_id)
      // let start = this.getWeekNumber(new Date(userplanning.startdate))
      // let end = this.getWeekNumber(new Date(userplanning.enddate))
      // let weeknumber = parseInt(row.item.week.substring(1, 4))
      let contractstart = new Date(userplanning.startdate);
      let contractend = new Date(row.enddate);
      // var startdate
      // if(start[1]>weeknumber){
      //   startdate = new Date(start[0]+1, 0, 1);
      // } else {
      //   startdate = new Date(start[0], 0, 1);
      // }
      
      // if(startdate.getDay()==1){
      //   startdate.setDate(startdate.getDate() + ((weeknumber-1) * 7));
      // } else {
      //   startdate.setDate(startdate.getDate() + (weeknumber * 7));
      // }
      // if (startdate < new Date(userplanning.startdate)) {
      //   startdate = new Date(userplanning.startdate)
      // }
      // let enddate = new Date(end[0], 0, 1);
      // if(enddate.getDay()==1){
      //   enddate.setDate(enddate.getDate() + ((weeknumber-1) * 7) + 2);

      // } else{
      //   enddate.setDate(enddate.getDate() + ((weeknumber) * 7) + 2);

      // }
      // if (enddate > new Date(userplanning.enddate)) {
      //   enddate = new Date(userplanning.enddate)
      // }
      // enddate.setDate(startdate.getDate()+6)
///dayjs method

        let startyear = parseInt(this.getWeekNumber(new Date(userplanning.startdate))[0])
        let startweek = parseInt(this.getWeekNumber(new Date(userplanning.startdate))[0])
        let endyear = parseInt(this.getWeekNumber(new Date(userplanning.enddate))[0])
        let endweek = parseInt(this.getWeekNumber(new Date(userplanning.enddate))[0])
        

        var startdate = new Date(this.getWeekOfYear(parseInt(startyear),startweek)[0])
        var enddate =  new Date(this.getWeekOfYear(parseInt(endyear),endweek)[6])

      let startmonday = this.getMonday(startdate);
      var loop = new Date(startmonday);
      enddate = new Date(startmonday)
      enddate.setDate(startmonday.getDate()+6)
      let alldates = []
      // if(row.item.user_id==1343){
      //   console.log(startdate, enddate)
      // }
      while (loop < enddate) {
        let thisday = this.dateToYYYYMMDD(loop)
        if (loop.setHours(0,0,0) < contractstart.setHours(0,0,0) || loop.setHours(0,0,0) > contractend.setHours(0,0,0)) {
          alldates.push("0")
        } else {
          alldates.push(thisday)
        }
        var newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
      if (alldates) {
        for (var adate of alldates) {
          for (var ahour of this.hours) {
            if (ahour.contract_id == contract.id && ahour.workdate == adate && ahour.user_id == contract.user_id) {
              totalnormal += parseFloat(ahour.hours)
              for (const onregel of contract.onregelmatig) {
                let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
                if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                  if (declaratie_cat.name in declaraties) {
                    if(!isNaN(parseFloat(ahour[declaratie_cat.name]))&& ahour[declaratie_cat.name]!=null){
                      declaraties[declaratie_cat.name] += parseFloat(ahour[declaratie_cat.name])
                    }
                  } else {
                    if(!isNaN(parseFloat(ahour[declaratie_cat.name]))&& ahour[declaratie_cat.name]!=null){
                      declaraties[declaratie_cat.name] = parseFloat(ahour[declaratie_cat.name])
                    }
                  }
                }
              }
            }
          }
        }
      }
      var retstring = this.$i18n.t("titles.project_hours") + "<strong> " + totalnormal + "</strong><br/>";
      for (var k in declaraties) {
        retstring += k + "<strong> " + declaraties[k].toFixed(2) + "</strong><br/>"
      }
      return retstring
    },
    thisweekdays() {
      let startyear = this.startw.substring(0, 4)
      let startweek = parseInt(this.startw.substring(6))
      return this.getWeekOfYear(parseInt(startyear), parseInt(startweek))
      /*
      var d = new Date(startyear, 0, 1);
      // if(d.getDay()==1){
        d.setDate(d.getDate() + ((startweek-1) * 7));
      } else {
        d.setDate(d.getDate() + (startweek * 7));
      }
      var startdate = this.getMonday(d)
      // console.log(startdate, startweek)
      // let endyear = this.endw.substring(0, 4)
      let endweek = parseInt(this.endw.substring(6))
      // d = new Date(endyear, 0, 1);
      // if(d.getDay()==1){
      //   d.setDate(d.getDate() + ((endweek-1) * 7));
      // } else {
      //   d.setDate(d.getDate() + (endweek * 7));
      // }
      // console.log(d, endweek)
      // var enddate = this.getMonday(d.getTime() + 7 * 24 * 60 * 60 * 1000)
      let weeksdif = endweek-startweek;
            var enddate = startdate.getTime() + 6 * 24 * 60 * 60 * 1000 +(weeksdif*7 * 24 * 60 * 60 * 1000)
            // console.log(enddate, endweek)

      var loop = new Date(startdate);
      let alldates = []
      while (loop < enddate) {
        let thisday = this.dateToYYYYMMDD(loop)
        var newDate = loop.setDate(loop.getDate() + 1);
        alldates.push(thisday)
        loop = new Date(newDate);
      }
      // console.log("alldates", alldates)
      return alldates
      */
    },
    setAbsent(item) {
      this.selected_contractID=item.contract_id
      this.selected_week=item.realweek;
      this.selected_year = item.year
      this.$bvModal.show('BSAbsentModalID')

      // try {
      //   axios.post('/api/set_week_absent', {contract_id:item.contract_id, week:item.realweek, year:item.year, absent:1, reason:"vacation"}).then(async () => {
      //   this.$store.dispatch('get_invoiceextra')
      //   })
      // } catch (err) {
      //   console.log(err)
      // }
    },
    setPresent(item) {
      try {
        axios.post('/api/set_week_absent', {contract_id:item.contract_id, week:item.realweek, year:item.year, absent:0, reason:""}).then(async () => {
          this.$store.dispatch('get_invoiceextra')
        })
      } catch (err) {
        console.log(err)
      }
    }
  },
  components:{BSAbsentModal},
  watch: {
    startw: function () {
      this.allhours = this.thisweekdays()
    },
    endw: function () {
      this.allhours = this.thisweekdays()
    }
  }
}
</script>
    