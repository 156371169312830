<template>
  <b-container v-if="createAny('users')"  fluid>
    {{ user }}
  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'


export default {
  name: 'user-detail',
  data() {
    return {

    }
  },
  mounted() {
     this.user_id = Number(this.$route.params.id)
  },

  computed: {
    user: {
      get() {
        return this.user_by_id(this.$route.params.id)
      },
    },

    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      'branche_by_id',
      'createAny',
      'planning_by_id',
      'contract_by_id',
      'tag_by_id',
      'project_by_id',
      'username_by_id',
      'find_planning',
      'find_contract_id',
      'user_photo_by_id',
      'server_url',
      'append_data_string',
      'confirm_modal',
      'company_by_id',
      'find_last_planning_user'
    ]),
    ...mapState([
      'users',
      'current_user',
      'branches',
      'workfields',
      'avatars',
      'projects',
      'contracts',
      'hours',
      'companies',
      'planning',
    ]),

  },
  methods: {

  },
}
</script>